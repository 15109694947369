<template>
  <div class="relative">
    <BackFa v-if="isRtl" :text="$t('back')" />
    <div class="m-5 mt-6 bg-white">
      <div class="border border-gray-400">
        <div class="p-4 flex justify-between items-center">
          <h3 :class="{ 'text-rose': isLtr }" class="font-bold">
            {{ $t('title_repors') }}
            <span class="text-rose">{{ $t('labratory_title') }}</span>
          </h3>
          <BackFa v-if="isLtr" :text="$t('back')" />
        </div>

        <BaseTable
          class="text-right mb-6"
          header-class="bg-gray-50 text-gray-400 text-sm"
          :headers="headers"
          :items="items"
          theme-type="even"
        >
          <template #item:docter="{ item }">
            <div class="flex items-center">
              <div
                :class="{ 'ml-2': isRtl, 'mr-2': isLtr }"
                class="bg-cyan-400 h-10 w-10 rounded-lg"
              ></div>
              <div class="font-extralight">
                <h3 class="text-base">{{ item.docter }}</h3>
                <h3 class="text-xs font-thin text-gray-400">
                  {{ item.specialty }}
                </h3>
              </div>
            </div>
          </template>
          <template #item:allergy="{ item }">
            <span
              @click="goto(item.to)"
              :class="{
                'hover:bg-rose cursor-pointer duration-200':
                  item.allergy === 'ناشناس' || item.allergy === 'Visualisation',
              }"
              class="bg-cyan-400 text-white px-6 py-1 rounded-2xl shadow-lg"
              >{{ item.allergy }}</span
            >
          </template>
          <template #item:image>
            <i class="text-2xl text-gray-400 fal fa-file-download"></i>
          </template>
        </BaseTable>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTable from '@/components/BaseTable'
import BackFa from '../components/back/BackFa.vue'

export default {
  name: 'Laboratory',
  components: { BaseTable, BackFa },
  computed: {
    headers() {
      return [
        {
          key: 'docter',
          text: `${this.$t('doctor_reports')}/${this.$t('specialty_reports')}`,
        },
        {
          key: 'date',
          text: this.$t('date_reports'),
        },
        {
          key: 'subject',
          text: this.$t('subject_reports2'),
        },
        {
          key: 'allergy',
          text: this.$t('allergy_reports'),
        },
        {
          key: 'image',
          text: '',
          align: 'center',
        },
      ]
    },
    items() {
      return [
        {
          date: this.convertDate('2021-1-1'),
          specialty: this.$t('labratory_specialty'),
          docter: this.$t('labratory_docter'),
          subject: this.$t('labratory_subject'),
          selected: true,
          allergy: this.$t('allergy_labratory'),
        },
        {
          date: this.convertDate('2021-1-2'),
          specialty: this.$t('labratory_specialty'),
          docter: this.$t('labratory_docter'),
          subject: this.$t('labratory_subject2'),
          selected: false,
          allergy: this.$t('allergy_labratory3'),
          to: { name: 'Laboratory2' },
        },
        {
          date: this.convertDate('2021-2-1'),
          specialty: this.$t('labratory_specialty'),
          docter: this.$t('labratory_docter'),
          subject: this.$t('labratory_subject3'),
          selected: false,
          allergy: this.$t('allergy_labratory2'),
        },

        //           "../assets/images/theme/edit-icon.png"
        // "../assets/images/theme/text-icon.png"
        // "../assets/images/theme/comment-icon.png"
        // "../assets/images/theme/text-left-icon.png"
        // "../assets/images/theme/edit-pen-icon.png"
        // "../assets/images/theme/circle-icon.png"
        // "../assets/images/theme/text-icon.png"
      ]
    },
  },
  methods: {
    goto(to) {
      !!to && this.$router.push(to)
    },
  },
}
</script>

<style></style>
